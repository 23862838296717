import React, { useEffect, useState, useContext } from 'react';

import APICtx from '#api'
import Form, { Input, FileUpload, Select } from '#Form'
import Dialog from '#Dialog'

const FileCreate = ({ folderId }) => {
	const api = useContext(APICtx)
	const [ folders, setFolders ] = useState([])

	const formDef = {
		callSet: "docs-repo/file/create",
		// callGet: _id && "docs-repo/file/get",
		extraData: { folderId },
	}
	return (
		<Form {...formDef}>
			<FileUpload label="Nuovi files" name="files" tokenEndpoint="docs-repo/get-upload-token" multiple />
		</Form>
	)
}
const FileUpdate = ({ _id, folderId }) => {
	const api = useContext(APICtx)
	const [ folders, setFolders ] = useState([])

	useEffect(() => {
		api.call('docs-repo/folder/options', { _id }).then(setFolders)
	}, [])

	const formDef = {
		_id,
		callSet: "docs-repo/file/update",
		callGet: "docs-repo/file/get",
		extraData: { folderId },
	}
	return (
		<Form {...formDef}>
			<Input label="Nome file" name="filename" required />
			<Select label="Cartella" name="folderId" options={folders} emptyLabel="-- cartella principale --" />
		</Form>
	)
}

export const FileDialog = ({ _id, _new, handleClose, folderId }) => {
	const open = Boolean(_new || _id)
	const title = (_id ? 'Modifica documento' : 'Crea uno o più documenti')

	return (
		<Dialog
			handleClose={handleClose}
			open={open}
			title={title}
			width="sm"
		>
			{ Boolean(!_id) && <FileCreate folderId={folderId} /> }
			{ Boolean(_id) && <FileUpdate _id={_id} folderId={folderId} /> }
		</Dialog>
	)
}

export const FolderEdit = ({ _id }) => {
	const api = useContext(APICtx)
	const [ usersOpts, setUsersOpts ] = useState([])

	useEffect(() => {
		api.auth.usersAsOptions().then(setUsersOpts)
	}, [])

	const formDef = {
		_id,
		callSet: _id ? "docs-repo/folder/update" : "docs-repo/folder/create",
		callGet: _id && "docs-repo/folder/get",
	}
	return (
		<Form {...formDef}>
			<Input label="Nome cartella" name="label" required />
			<Select label="Limitazione accesso" name="accessRestriction" options={usersOpts} emptyLabel="-- nessuna limitazione --" />
		</Form>
	)
}
export const FolderDialog = ({ _id, _new, handleClose }) => {
	const open = Boolean(_new || _id)
	const title = (_id ? 'Modifica' : 'Crea nuova') + ' cartella'

	return (
		<Dialog
			handleClose={handleClose}
			open={open}
			title={title}
			width="sm"
		>
			<FolderEdit _id={_id} />
		</Dialog>
	)
}
