import React, { useContext, useEffect, useMemo } from 'react';
import { Routes, Route, useNavigate } from "react-router-dom";

import APICtx, { useApi } from '#api'
import Screen, { TplWSidebar as Tpl } from '#Template'
import { faTruckClock, faTruck, faTruckArrowRight, faBook, faUserTie, faBoxCheck, faSitemap, faTag, faTruckFlatbed, faRoad, faCommentXmark, faFolders } from '@fortawesome/pro-solid-svg-icons'
import logoImg from './logo.png'

import TransportsCalPage from './transports/Cal'
import TransportsTrucksListPage from './transports/TrucksList'
import TransportsTrucksEditPage from './transports/TrucksEdit'
import TransportsTrucksTasksPage from './transports/TrucksTasks'
import TransportsPlannerPage from './transports/Planner'
import TransportsScheduleListPage from './transports/ScheduleList'
import TransportsScheduleEditPage from './transports/ScheduleEdit'
import TransportsReadyListPage from './transports/ReadyList'
import TransportsReadyEditPage from './transports/ReadyEdit'
import AssetsListPage from './assets/List'
import AssetsEditPage from './assets/Edit'

import TransportPricesListPage from './platforms/TransportPricesList'
import TransportPricesEditPage from './platforms/TransportPricesEdit'
import DatasheetListPage from './platforms/DatasheetList'
import DatasheetEditPage from './platforms/DatasheetEdit'
import OffersListPage from './platforms/OffersList'
import OffersEditPage from './platforms/OffersEdit'
import OffersStatusEditPage from './platforms/OffersStatusEdit'

import AgentsListPage from './configs/AgentsList'
import AgentsEditPage from './configs/AgentsEdit'
import ProdCategoriesListPage from './configs/ProdCategoriesList'
import ProdCategoriesEditPage from './configs/ProdCategoriesEdit'
import LostOfferReasonListPage from './configs/LostOfferReasonsList'
import LostOfferReasonEditPage from './configs/LostOfferReasonsEdit'

import DocsListPage from './docsRepo/List'
import { Typography } from '@mui/material';

const Router = () => (
	<Routes>
		<Route path="/transports/cal/:date" element={<TransportsCalPage />} />
		<Route path="/transports/cal" element={<TransportsCalPage />} />

		<Route path="/transports/trucks/tasks/:date" element={<TransportsTrucksTasksPage />} />
		<Route path="/transports/trucks/tasks" element={<TransportsTrucksTasksPage />} />
		<Route path="/transports/trucks/edit/:_id" element={<TransportsTrucksEditPage />} />
		<Route path="/transports/trucks/edit" element={<TransportsTrucksEditPage />} />
		<Route path="/transports/trucks" element={<TransportsTrucksListPage />} />
		
		<Route path="/transports/planner/:date" element={<TransportsPlannerPage />} />
		<Route path="/transports/schedule/:date" element={<TransportsScheduleListPage />} />
		<Route path="/transports/schedule/edit/:_id" element={<TransportsScheduleEditPage />} />
		<Route path="/transports/schedule/edit" element={<TransportsScheduleEditPage />} />
		
		<Route path="/transports/ready" element={<TransportsReadyListPage />} />
		<Route path="/transports/ready/edit/:_id" element={<TransportsReadyEditPage />} />
		<Route path="/transports/ready/edit" element={<TransportsReadyEditPage />} />
		
		<Route path="/assets/:assetType/edit/:_id" element={<AssetsEditPage />} />
		<Route path="/assets/:assetType/edit" element={<AssetsEditPage />} />
		<Route path="/assets/:assetType" element={<AssetsListPage />} />

		<Route path="/platforms/transport-prices/edit/:_id" element={<TransportPricesEditPage />} />
		<Route path="/platforms/transport-prices/edit" element={<TransportPricesEditPage />} />
		<Route path="/platforms/transport-prices" element={<TransportPricesListPage />} />
		<Route path="/platforms/offers/edit-status/:_id" element={<OffersStatusEditPage />} />
		<Route path="/platforms/offers/edit/:_id" element={<OffersEditPage />} />
		<Route path="/platforms/offers/edit" element={<OffersEditPage />} />
		<Route path="/platforms/offers" element={<OffersListPage />} />
		<Route path="/platforms/edit/:_id" element={<DatasheetEditPage />} />
		<Route path="/platforms/edit" element={<DatasheetEditPage />} />
		<Route path="/platforms" element={<DatasheetListPage />} />
		
		<Route path="/docs-repo/:folderId" element={<DocsListPage />} />
		<Route path="/docs-repo" element={<DocsListPage />} />

		<Route path="/agents/edit/:_id" element={<AgentsEditPage />} />
		<Route path="/agents/edit" element={<AgentsEditPage />} />
		<Route path="/agents" element={<AgentsListPage />} />
		<Route path="/products/categories/edit/:_id" element={<ProdCategoriesEditPage />} />
		<Route path="/products/categories/edit" element={<ProdCategoriesEditPage />} />
		<Route path="/products/categories" element={<ProdCategoriesListPage />} />
		<Route path="/lost-offer-reasons/edit/:_id" element={<LostOfferReasonEditPage />} />
		<Route path="/lost-offer-reasons/edit" element={<LostOfferReasonEditPage />} />
		<Route path="/lost-offer-reasons" element={<LostOfferReasonListPage />} />

		<Route path="/" element={<Home />} />
	</Routes>
)

const Home = () => {
	const api = useContext(APICtx)
	const navigate = useNavigate()

	useEffect(() => {
		api.chkAuth('transport-driver') && navigate('/transports/trucks/tasks', { replace:true })
	}, [])
	return (
		<Tpl title="Socar S.p.A.">
			<Typography variant="h5">Benvenuto sul portale staff di Socar S.p.A.</Typography>
		</Tpl>
	)
}
const Blank = () => (
	<Tpl title="Socar S.p.A." />
)

const useMenu = (api) => useMemo(() => {
	const menu = []

	const menuTransport = []
	if(api.chkAuthAny([ 'transport-user', 'transport-manager' ]))
		menuTransport.push({ label:"Calendario trasporti", icon:faTruckClock, linkTo:"/transports/cal" })
	if(api.chkAuthAny([ 'transport-user', 'transport-manager', 'transport-driver' ]))
		menuTransport.push({ label:"Pronti per il ritiro", icon:faBoxCheck, linkTo:"/transports/ready" })
	if(api.chkAuth('transport-driver'))
		menuTransport.push({ label:"Trasporti odierni", icon:faTruckArrowRight, linkTo:"/transports/trucks/tasks" })
	if(menuTransport.length)
		menu.push(menuTransport)

	const menuAssets = []
	if(api.auth.chk('assets-forklifts'))
		menuAssets.push(
			{ label:"Reg. Frontali", icon:faBook, linkTo:"/assets/counterbalanced" },
			{ label:"Reg. Magazzinieri", icon:faBook, linkTo:"/assets/warehouse" },
			{ label:"Reg. CDT", icon:faBook, linkTo:"/assets/cdt" },
			{ label:"Reg. Usati", icon:faBook, linkTo:"/assets/used" },
			{ label:"Reg. Attrezzature", icon:faBook, linkTo:"/assets/equipment" },
		)
	if(api.auth.chk('assets-platforms'))
		menuAssets.push(
			{ label:"Reg. Piattaforme", icon:faBook, linkTo:"/assets/aerialplatform" },
		)
	if(menuAssets.length)
		menu.push(menuAssets)

	const menuPlatforms = []
	if(api.chkAuthAny([ 'offers-trader', 'offers-dataentry' ]))
		menuPlatforms.push(
			{ label:"Offerte", icon:faTag, linkTo:"/platforms/offers" },
			{ label:"Schede tecniche", icon:faTruckFlatbed, linkTo:"/platforms" },
			{ label:"Listino trasporti", icon:faRoad, linkTo:"/platforms/transport-prices" },
		)
	if(menuPlatforms.length)
		menu.push(menuPlatforms)
	
	if(api.auth.chk('docsrepo-read'))
		menu.push([
			{ label:"Archivio documenti", icon:faFolders, linkTo:"/docs-repo" }
		])
	
	const menuConf  = []
	if(api.chkAuth('transport-admin' ))
		menuConf.push({ label:"Gestione autocarri", icon:faTruck, linkTo:"/transports/trucks" })
	if(api.chkAuthAny([ 'conf-admin' ]))
		menuConf.push(
			{ label:"Gestione agenti", icon:faUserTie, linkTo:"/agents" },
			{ label:"Categorie prodotti", icon:faSitemap, linkTo:"/products/categories" },
			{ label:"Cause offerta persa", icon:faCommentXmark, linkTo:"/lost-offer-reasons" },
		)
	if(menuConf.length)
		menu.push(menuConf)
	
	return menu
}, [ api ])

export default function App() {
	const api = useApi()
	const menu = useMenu(api)

	return (
		<APICtx.Provider value={api}>
			<Screen toolAuth menu={menu} logo={logoImg}>
				{api.chkAuth() ? <Router /> : <Blank />}
			</Screen>
		</APICtx.Provider>
	)
}