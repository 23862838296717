import React, { useState, useContext, useEffect, useMemo } from 'react';

import APICtx from '#api'
import { TplWSidebar as Tpl } from '#Template'
import DataList from '#DataList'
import SpeedDial from '#SpeedDial'
import {
	FileDialog,
	FolderDialog,
} from './Edit'

import {
	faEdit,
	faTrash,
	faMagnifyingGlass,
	faDownload,
	faFilePlus,
	faFolderPlus,
	faFolderGrid,
	faFolderArrowUp,
} from '@fortawesome/pro-regular-svg-icons'
import { useParams } from 'react-router-dom';
import { Typography } from '@mui/material';

const FOLDER_UP_ID = '_up'

const filesTabDef = [
	{ label:"Nome file", field:'filename' },
]
const useFoldersTabDef = () => {
	const api = useContext(APICtx)
	const aclRW = api.auth.chk('docsrepo-write')

	return useMemo(() => {
		const def = [
			{ label:"Cartella", field:'label' },
		]
		if(aclRW)
			def.push({ label:"Limitazione accesso", field:'accessRestriction', type:'user' })
		return def
	}, [ aclRW ])
}

const Folders = ({ ids, handleRefresh, setFolderEdit }) => {
	const api = useContext(APICtx)
	const foldersTabDef = useFoldersTabDef()

	const handleDelete = ({ _id, label }) => {
		api.dialogConfirm({
			text: 'Sei sicuro di voler cancellare la cartella "' + label + '"?',
			onConfirm: () => api.call('docs-repo/folder/del', { _id }).then(handleRefresh),
		})
	}

	const aclRW = api.auth.chk('docsrepo-write')

	const defBtns = [
		{
			icon: faFolderGrid,
			label: 'Apri',
			linkTo: row => '/docs-repo/' + row._id,
			show: row => row._id !== FOLDER_UP_ID,
		},
		{
			icon: faFolderArrowUp,
			label: 'Torna',
			linkTo: '/docs-repo',
			show: row => row._id === FOLDER_UP_ID,
		},
		{
			icon: faEdit,
			label: 'Modifica',
			onClick: setFolderEdit,
			show: row => aclRW && row._id !== FOLDER_UP_ID,
		},
		{
			icon: faTrash,
			label: 'Elimina',
			onClick: handleDelete,
			show: row => aclRW && row._id !== FOLDER_UP_ID,
		},
	]

	const getRow = _id => api.call('docs-repo/folder/get', { _id })
	return (
		<DataList def={foldersTabDef} rowActions={defBtns} ids={ids} getRow={getRow} />
	)
}

const Files = ({ ids, handleRefresh, setFileEdit }) => {
	const api = useContext(APICtx)

	const handleEdit = row => setFileEdit(row)
	const handleDelete = ({ _id, filename }) => {
		api.dialogConfirm({
			text: 'Sei sicuro di voler cancellare il file "' + filename + '"?',
			onConfirm: () => api.call('docs-repo/file/del', { _id }).then(handleRefresh),
		})
	}

	const aclRW = api.auth.chk('docsrepo-write')

	const defBtns = [
		{
			icon: faEdit,
			label: 'Modifica',
			onClick: handleEdit,
			show: aclRW,
		},
		{
			icon: faMagnifyingGlass,
			label: 'Anteprima',
			onClick: row => api.storage.download(row.jwt, true),
			show: row => Boolean(row.jwt),
		},
		{
			icon: faDownload,
			label: 'Scarica',
			onClick: row => api.storage.download(row.jwt, false),
			show: row => Boolean(row.jwt),
		},
		{
			icon: faTrash,
			label: 'Elimina',
			onClick: handleDelete,
			show: aclRW,
		},
	]

	const getRow = _id => api.call('docs-repo/file/get', { _id })
	return (
		<DataList def={filesTabDef} rowActions={defBtns} ids={ids} getRow={getRow} />
	)
}

export default function DocsList() {
	const api = useContext(APICtx)
	const { folderId } = useParams()
	const [ files, setFiles ] = useState([])
	const [ folders, setFolders ] = useState([])
	const [ fileEdit, setFileEdit ] = useState(false)
	const [ folderEdit, setFolderEdit ] = useState(false)

	const aclRW = api.auth.chk('docsrepo-write')

	const handleFileRefresh = () => api.call('docs-repo/file/list', { folderId }).then(setFiles)
	const handleFolderRefresh = () => api.call('docs-repo/folder/list', { folderId }).then(setFolders)
	useEffect(() => {
		handleFileRefresh()
		handleFolderRefresh()
	}, [ folderId ])

	const actions = useMemo(() => {
		const actions = [
			{
				tooltip: 'Aggiungi un o più files',
				icon: faFilePlus,
				onClick: () => setFileEdit({ _new:true }),
			},
		]
		if(!folderId)
			actions.push({
				tooltip: 'Aggiungi cartella',
				icon: faFolderPlus,
				onClick: () => setFolderEdit({ _new:true }),
			})
		return actions
	}, [ folderId ])

	return (
		<Tpl title='Archivio documenti'>
			<FileDialog
				{...fileEdit}
				handleClose={() => {
					fileEdit?.handleRowRefresh?.()
					handleFileRefresh()
					setFileEdit(null)
				}}
				folderId={folderId}
				handleRefresh={handleFileRefresh}
			/>
			<FolderDialog
				{...folderEdit}
				handleClose={() => {
					folderEdit?.handleRowRefresh?.()
					handleFolderRefresh()
					setFolderEdit(null)
				}}
				folderId={folderId}
				handleRefresh={handleFolderRefresh}
			/>

			<Typography variant='h5'>Cartelle</Typography>
			<Folders
				folderId={folderId}
				folderEdit={folderEdit}
				ids={folders}
				setFolderEdit={setFolderEdit}
				handleRefresh={handleFolderRefresh}
			/>
			<Typography variant='h5' sx={{ mt:2 }}>Documenti</Typography>
			<Files
				folderId={folderId}
				fileEdit={fileEdit}
				ids={files}
				setFileEdit={setFileEdit}
				handleRefresh={handleFileRefresh}
			/>

			{ aclRW && <SpeedDial actions={actions} /> }
		</Tpl>
	)
}
